<template>
    <v-container>
        <h3>{{$t('message.detail-item-title')}}</h3>
        <v-card>
            <v-col class="pr-2 pb-2" cols="12">
                <form>
                    <v-row>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label" >{{$t('message.id')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.id }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.shop')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.store_name }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.product-name')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.item_name }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label lable-class">{{$t('message.category')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.category_name }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.itemlistdetail-price')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.price }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.tax')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.tax }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.initial-stock')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.stock_beginning }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.item-code')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.item_code }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.width')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.width }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.length')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.length }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.height')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.height }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.weight')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.weight }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{ $t("message.label-tags") }}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.tag }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.status')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ is_active }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{ $t("message.label-tax_rate") }}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.tax_rate }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{ $t("message.label-Tax_process_category") }}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ tax_category }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{ $t("message.label-locker") }}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ is_locker }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.description')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.description }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.specification')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                        <span class="">{{ itemDetail.specification }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                        <v-label class="label">{{$t('message.image')}}</v-label>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <template>
                                <v-container class="">
                                <v-row class="mb-6" no-gutters>
                                    <v-col v-for="(img, i) in itemDetail.images" :key="i">
                                        <v-img
                                            :lazy-src="img.image_url"
                                            :src="img.image_url"
                                            max-height="150px"
                                            max-width="180px"
                                            class=""
                                        ></v-img>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </template>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-card>
        <div class="d-flex flex-row-reverse mt-3 me-0" tile>
            <div class="m-2">
                <v-btn color="grey" @click="$router.go(-1)">
                    {{$t('message.back-button')}}
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'ItemListDetail',
    data() {
        return {
            notFound: require("../assets/image-not-found.png")
        }
    },
    methods: {
        itemListDetail () {
            let id = this.$route.params.id
            return this.$store.dispatch('itemList_module/fetchItemListDetail', id)
        },
        getImage(itemDetail){
            if(itemDetail.images.length !== 0){
                return itemDetail.images[0].image_url
            }else{
                return this.notFound
            }
        }
    },
    computed: {
        is_active() {
            let cekIsActive = this.$store.state.itemList_module.itemDetail.is_active
            if(cekIsActive == 1) {
                return this.$t("message.active")
            }else {
                return this.$t("message.inactive")
            }
        },
        tax_category() {
            let taxCategory = this.$store.state.itemList_module.itemDetail.tax_category
            if(taxCategory == 1) {
                return "外税"
            } else if(taxCategory == 2) {
                return "内税"
            } else {
                return "非課税"
            }
        },
        is_locker() {
            let cekLocker = this.$store.state.itemList_module.itemDetail.is_locker
            if(cekLocker == 1) {
                return "可"
            } else {
                return "不可"
            }
        },
        itemDetail() {
            return this.$store.state.itemList_module.itemDetail
        },
    },
    created () {
        this.itemListDetail()
    }

}
</script>

<style>

</style>